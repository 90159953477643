import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Nav, NavItem, NavLink, Progress, TabContent, TabPane,
} from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import NotificationSystem from 'rc-notification';
import InfoTab from './tabs/InfoTab';
import NotificationsTab from './tabs/NotificationsTab';
import PlansTab from './tabs/PlansTab';
import { updateName } from '../../../redux/actions/userActions';
import BillingTab from './tabs/BillingTab';
import { BasicNotification, LinkNotification } from '../../../shared/components/notifications/Notification';
import SignatureTab from './tabs/SignatureTab';
import InvoicesTab from './tabs/InvoicesTab';
import BlacklistTab from './tabs/BlacklistTab';
import ModalDetailBlacklist from '../../../shared/components/modals/ModalDetailBlacklist';
// import showResults from './Show';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({ notification, position }, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: { top: 30, left: 'calc(100vw - 100%)' },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: { top: 0, left: 0 },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class SettingsTabs extends PureComponent {
  static defaultProps = {
    firstName: '',
    lastName: '',
    email: '',
    signature: '',
    notificationView: 0,
    notificationDeck: 0,
    notificationMessage: 0,
    notificationDeclined: 0,
    notificationBlog: 0,
    notificationNewsletter: 0,
    investorInstant: false,
    investorWeekly: false,
    investorMonthly: false,
    willRenew: false,
    isTrial: false,
    isPayingCustomer: false,
    isCustomer: false,
    datePlanEnd: '',
    dateTrialEnd: '',
    dateTrialCancelled: '',
    planType: '',
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      invoices: [],
      blacklist: [],
      activeTab: '1',
      downloading: false,
      downloadingInvoice: true,
      downloadingBlacklist: true,
      user: JSON.parse(localStorage.getItem('user')),
      modalDetailBlacklist: false,
      tableDetailBlacklist: [],
    };
  }

  componentDidMount = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    // the URLSearchParams constructor. We get the useLocation()s search property.
    if (window.location.search === '?tab=notifications') {
      this.toggle('2');
    }
    this.setState({ downloadingInvoice: true });
    axios.get('/user/invoices')
      .then((response) => {
        this.setState({ invoices: response.data.sort().reverse(), downloadingInvoice: false });
      }).catch(() => {
        this.setState({ invoices: null, downloadingInvoice: false });
      });
    if (user && user.type === 'startup') {
      this.getBlacklist();
    }
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  };

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  getBlacklist() {
    axios.get('/user/blacklist')
      .then((response) => {
        this.setState({ blacklist: response.data.sort().reverse(), downloadingBlacklist: false });
      }).catch(() => {
        this.setState({ blacklist: null, downloadingBlacklist: false });
      });
  }

  updateProfile = (values) => {
    const { dispatch, history } = this.props;
    this.setState({ downloading: true });
    axios.post('/user/account', queryString.stringify({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      password: values.password,
      new_password: values.new_password,
      new_password_check: values.new_password_check,
    })).then((response) => {
      if (response.data) {
        this.setState({ downloading: false }, () => this.showNotifSuccess());
        dispatch(updateName(values.firstName, values.lastName, values.email));

        // If we change the user's email, we have to logout login again.
        if (response.data.email_changed) {
          // eslint-disable-next-line no-alert
          window.alert('You have changed the email address associated with your account.'
            + 'For security reasons, please log in again.');
          history.push('/logout');
        }
      }
    }).catch((error) => {
      if (error.response
        && error.response.data.message
        && error.response.data.message.length > 0) {
        this.showNotifWarning(error.response.data.message);
      } else {
        this.showNotifWarning(error.response.data.message);
      }
    });
  };

  updateNotifications = (values) => {
    const { user } = this.state;
    let type = 'startup';
    if (user.type === 'investor') {
      type = 'investor';
    }
    this.setState({ downloading: true });
    axios.post('/user/notifications_preferences', queryString.stringify({
      view: values.view,
      deck: values.deck,
      declined: values.declined,
      message: values.message,
      blog: values.blog,
      newsletter: values.newsletter,
      investorInstant: values.investorInstant,
      investorWeekly: values.investorWeekly,
      investorMonthly: values.investorMonthly,
      type,
    })).then((response) => {
      if (response.data && response.data.success) {
        this.showNotifSuccess();
        this.setState({ downloading: false });
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  };

  updatePaymentMethod = (paymentMethod, errorMessage) => {
    if (errorMessage) {
      this.showNotifWarning(errorMessage);
      return;
    }

    axios.post('/subscription/change_payment_method', queryString.stringify({
      payment_method_id: paymentMethod.id,
    })).then((response) => {
      if (response.data && response.data.success) {
        this.showNotifSuccess();
      } else if (!response.data.success && response.data.message) {
        this.showNotifWarning(errorMessage);
      }
    }).catch(() => {
      this.showNotifWarning(errorMessage);
    });
  }

  updatePlan = (plan) => {
    if (!plan || plan === 'free') {
      return;
    }

    this.setState({ downloading: true });
    axios.post('/subscription/switch', queryString.stringify({ plan }))
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({ downloading: false }, () => this.showNotifSuccess());
        } else if (response.data && response.data.redirect) {
          window.location = response.data.url;
        } else {
          // Handle case where success is false but no redirect
          this.setState({
            downloading: false,
          }, () => this.showNotifWarning(response.data.error || 'An error occurred with your plan change'));
        }
      }).catch((error) => {
        this.setState({
          downloading: false,
        }, () => this.showNotifWarning(error.response.data.message));
      });
  };

  updateSignature = (values) => {
    this.setState({ downloading: true });
    axios.post('/user/signature', queryString.stringify({
      signature: values.signature,
    })).then((response) => {
      if (response.data) {
        this.setState({ downloading: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloading: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  }

  removeBlacklist = (filename) => {
    this.setState({ downloadingBlacklist: true });
    axios.post('/import/removeFromFilename', queryString.stringify({
      filename,
    })).then((response) => {
      if (response.data) {
        this.getBlacklist();
        this.setState({ downloadingBlacklist: false }, () => this.showNotifSuccess());
      }
    }).catch((error) => {
      this.setState({
        downloadingBlacklist: false,
      }, () => this.showNotifWarning(error.response.data.message));
    });
  }

  detailBlacklist = (filename) => {
    axios.post('/import/getDetailFromFilename', queryString.stringify({
      filename,
    })).then((response) => {
      if (response.data) {
        this.setState({ modalDetailBlacklist: true, tableDetailBlacklist: response.data });
      }
    }).catch((error) => {
      this.showNotifWarning(error.response.data.message);
    });
  }

  toggleModalDetailBlacklist = () => {
    const { modalDetailBlacklist } = this.state;
    this.setState({ modalDetailBlacklist: !modalDetailBlacklist });
  };

  showNotifSuccess = () => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message="Your information has been updated successfully"
    />,
    position: 'right-up',
  });

  showNotifWarning = errorMessage => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      message={errorMessage}
    />,
    position: 'right-up',
  });

  toggle = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      axios.post('/user/trackSettings', queryString.stringify({ tab })).then();
    }
  };

  render() {
    const {
      activeTab,
      downloading,
      user,
      invoices,
      blacklist,
      downloadingInvoice,
      downloadingBlacklist,
      modalDetailBlacklist,
      tableDetailBlacklist,
    } = this.state;
    const {
      firstName, lastName, email, notificationBlog, notificationDeck, notificationDeclined,
      notificationMessage, notificationNewsletter, notificationView, paymentPlans,
      investorInstant, investorWeekly, investorMonthly, signature, willRenew, dateTrialCancelled,
      datePlanEnd, dateTrialEnd, isTrial, planType, getMyInfo, isPayingCustomer, isCustomer,
    } = this.props;
    const initialValuesAccount = {
      firstName,
      lastName,
      email,
      password: '',
      new_password: '',
      new_password_check: '',
    };

    const initialValuesNotifications = {
      notificationView,
      notificationDeck,
      notificationMessage,
      notificationDeclined,
      notificationBlog,
      notificationNewsletter,
      investorInstant,
      investorWeekly,
      investorMonthly,
    };
    const initialValuesSignature = { signature };

    let downloadBlock = null;
    if (downloading) {
      downloadBlock = (
        <Progress animated value={100} className="progress-wrap--middle">
          Please wait..
        </Progress>
      );
    }

    let downloadInvoicesBlock = null;
    if (downloadingInvoice) {
      downloadInvoicesBlock = (
        <Progress animated value={100} className="progress-wrap--middle">
          Please wait..
        </Progress>
      );
    }

    let downloadBlacklistBlock = null;
    if (downloadingBlacklist) {
      downloadBlacklistBlock = (
        <Progress animated value={100} className="progress-wrap--middle">
          Please wait..
        </Progress>
      );
    }

    return (
      <Col md={12} lg={12} xl={12}>
        <Card>
          <CardBody className="pl-0">
            <div className="redesigned__tabs tabs tabs--vertical">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => {
                        this.toggle('1');
                      }}
                    >
                      Account
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => {
                        this.toggle('2');
                      }}
                    >
                      Notifications
                    </NavLink>
                  </NavItem>
                  {user.isCustomer && user.type === 'startup'
                    && (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '3' })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          Billing Method
                        </NavLink>
                      </NavItem>
                    )
                  }
                  {user.isCustomer && user.type === 'startup'
                    && (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '4' })}
                          onClick={() => {
                            this.toggle('4');
                          }}
                        >
                          Payment Plans
                        </NavLink>
                      </NavItem>
                    )
                  }
                  {user.type === 'startup'
                    && (
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === '5' })}
                          onClick={() => {
                            this.toggle('5');
                          }}
                        >
                          Signature
                        </NavLink>
                      </NavItem>
                    )
                  }
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '6' })}
                      onClick={() => {
                        this.toggle('6');
                      }}
                    >
                      Invoices
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '7' })}
                      onClick={() => {
                        this.toggle('7');
                      }}
                    >
                      Blacklist
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <InfoTab
                      onSubmit={val => this.updateProfile(val)}
                      initialValues={initialValuesAccount}
                      enableReinitialize
                    />
                    { downloadBlock }
                  </TabPane>
                  <TabPane tabId="2">
                    <NotificationsTab
                      onSubmit={val => this.updateNotifications(val)}
                      initialValues={initialValuesNotifications}
                      user={user}
                    />
                    { downloadBlock }
                  </TabPane>
                  {user.isCustomer && user.type === 'startup'
                    && (
                      <TabPane tabId="3">
                        <BillingTab handleForm={this.updatePaymentMethod} />
                        {downloadBlock}
                      </TabPane>
                    )
                  }
                  {user.isCustomer && user.type === 'startup'
                    && (
                      <TabPane tabId="4">
                        <PlansTab
                          handleUpdatePlan={this.updatePlan}
                          paymentPlans={paymentPlans}
                          willRenew={willRenew}
                          datePlanEnd={datePlanEnd}
                          dateTrialEnd={dateTrialEnd}
                          isTrial={isTrial}
                          planType={planType}
                          isPayingCustomer={isPayingCustomer}
                          isCustomer={isCustomer}
                          getMyInfo={getMyInfo}
                          dateTrialCancelled={dateTrialCancelled}
                        />
                        { downloadBlock }
                      </TabPane>
                    )
                  }
                  {user.type === 'startup'
                    && (
                      <TabPane tabId="5">
                        <SignatureTab
                          onSubmit={this.updateSignature}
                          /* eslint-disable-next-line no-undef */
                          initialValues={initialValuesSignature}
                        />
                        { downloadBlock }
                      </TabPane>
                    )
                  }
                  <TabPane tabId="6" className="pr-0">
                    <InvoicesTab
                      invoices={invoices}
                      downloading={downloadingInvoice}
                    />
                    { downloadInvoicesBlock }
                  </TabPane>
                  {user.type === 'startup'
                  && (
                    <TabPane tabId="7">
                      <BlacklistTab
                        blacklist={blacklist}
                        downloading={downloadingBlacklist}
                        removeBlacklist={filename => this.removeBlacklist(filename)}
                        detailBlacklist={filename => this.detailBlacklist(filename)}
                      />
                      { downloadBlacklistBlock }
                      <ModalDetailBlacklist
                        color="primary"
                        title="Details"
                        colored
                        displayed={modalDetailBlacklist}
                        data={tableDetailBlacklist}
                        toggleModal={() => this.toggleModalDetailBlacklist()}
                      />
                    </TabPane>
                  )
                  }
                </TabContent>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default withRouter(connect(null)(SettingsTabs));

SettingsTabs.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  notificationView: PropTypes.bool,
  notificationDeck: PropTypes.bool,
  notificationMessage: PropTypes.bool,
  notificationDeclined: PropTypes.bool,
  notificationNewsletter: PropTypes.bool,
  notificationBlog: PropTypes.bool,
  investorInstant: PropTypes.bool,
  investorWeekly: PropTypes.bool,
  investorMonthly: PropTypes.bool,
  signature: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  getMyInfo: PropTypes.func.isRequired,
  paymentPlans: PropTypes.shape({
    name: PropTypes.string,
    currency: PropTypes.string,
    monthly_price: PropTypes.number,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  willRenew: PropTypes.bool,
  isTrial: PropTypes.bool,
  isPayingCustomer: PropTypes.bool,
  isCustomer: PropTypes.bool,
  datePlanEnd: PropTypes.string,
  dateTrialEnd: PropTypes.string,
  dateTrialCancelled: PropTypes.string,
  planType: PropTypes.string,
};
