import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimeLineItem from '../../../shared/components/TimeLineItem';

const userIcon = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/user-icon.png`;

const TimelineHistory = ({ notifications, userType }) => (
  <Col md={12} id="timeline">
    <div className="timeline">
      {notifications.map((notification, index) => (
        <TimeLineItem
          /* eslint-disable-next-line no-nested-ternary */
          img={notification.avatar
            ? notification.type === 'investor'
              ? process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_INVESTORS_PATH + notification.avatar
              : process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_LOGOS_PATH + notification.avatar
            : userIcon}
          title={notification.title}
          date={notification.date}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <p>
            <a
              /* eslint-disable-next-line no-nested-ternary */
              href={notification.type === 'investor'
                ? `/investor/${notification.id}`
                : userType === 'investor'
                  ? `/startup/${notification.id}`
                  : `/founder/${notification.id}`}
              className="topbar__collapse-name text-blue"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong><u>{notification.name}</u></strong>
            </a>
            &nbsp;{notification.message}
            {notification.response
            && (
              <span className="response-declined text-muted">
                <br />
                <i>
                  &quot;{
                    notification.response.length > 150
                      ? `${notification.response.substring(0, 150)}...`
                      : notification.response
                  }&quot;
                </i>
              </span>
            )}
            {(notification.time && notification.time > 0)
              ? (
                <span className="time-spent">
                  <br />
                  {`Time Spent: ${
                    moment.utc(moment.duration(notification.time, 'seconds').as('milliseconds')).format('mm:ss')}m`}
                </span>
              )
              : (
                ''
              )}
          </p>
          <span
            className={`label label__notification ${notification.type === 'investor' ? 'label-blue' : 'label-violet'}`}
          >
            {notification.type}
          </span>
        </TimeLineItem>
      ))}
    </div>
  </Col>
);

TimelineHistory.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  userType: PropTypes.string.isRequired,
};

export default TimelineHistory;
