import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import ChatCard from './components/ChatCard';

class Chat extends PureComponent {
  render = () => {
    const { props } = this;
    return (
      <Container>
        <Row>
          <ChatCard id={props.match.params.id ? Number(props.match.params.id) : null} />
        </Row>
      </Container>
    );
  };
}

export default withRouter(Chat);
