/* eslint-disable react/no-array-index-key */

import React, { PureComponent } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import classNames from 'classnames';

import ChatContact from './ChatContact';
import ChatBubble from './ChatBubble';
import ChatSearch from './ChatSearch';
import ChatField from './ChatField';
import ChatTopbar from './ChatTopbar';
import { ContactsProps, CurrentUserProps } from '../../../shared/prop-types/ChatProps';
import { FullWideNotification } from '../../../shared/components/notifications/Notification';
import Modal from '../../../shared/components/modals/Modal';
import UploadFile from './UploadFile';
import AccountWarnings from '../../../shared/components/AccountWarnings';

export default class Chat extends PureComponent {
  static propTypes = {
    currentUser: CurrentUserProps.isRequired,
    contacts: ContactsProps.isRequired,
    showNotification: PropTypes.func.isRequired,
    currentChat: PropTypes.number,
  };

  static defaultProps = {
    currentChat: false,
  };

  // ? props.contacts.filter(c => c.id === props.currentChat)[0].messages
  constructor(props) {
    super(props);
    this.state = {
      currentMessages: props.currentChat && props.contacts.find(c => c.id === props.currentChat)
        ? props.contacts.find(c => c.id === props.currentChat).messages
        : props.contacts[0].messages,
      currentChat: props.currentChat && props.contacts.find(c => c.id === props.currentChat)
        ? props.contacts.find(c => c.id === props.currentChat).id
        : props.contacts[0].id,
      openContacts: false,
      messageValue: '',
      contacts: props.contacts,
      contactsList: props.contacts,
      showUpload: false,
      modal: false,
      modalEndTrial: false,
      sending: false,
      user: JSON.parse(localStorage.getItem('user')),
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.scrollChatToBottom();
  }

  submitForm = (event) => {
    event.preventDefault();
    const {
      messageValue, currentChat, contacts, user,
    } = this.state;
    const { currentUser } = this.props;

    const chatType = currentChat && contacts.find(c => c.id === currentChat)
      ? contacts.find(c => c.id === currentChat).type
      : contacts[0].type;

    if (
      !user.isCustomer && messageValue !== '' && messageValue.length > 0
      && user.type !== 'investor' && chatType === 'investor'
    ) {
      this.showModal();
    } else if (
      !user.isPayingCustomer && messageValue !== '' && messageValue.length > 0
      && user.type !== 'investor' && chatType === 'investor'
    ) {
      this.setState({ modalEndTrial: true });
    } else if (messageValue !== '' && messageValue.length > 0) {
      this.setState({ modalEndTrial: false });
      this.setState({ messageValue: '', sending: true });
      axios.post('/conversations/message', queryString.stringify({ id: currentChat, content: messageValue }))
        .then((response) => {
          if (response) {
            // find the index of this conversation
            const objIndex1 = contacts.findIndex(i => i.id === currentChat);

            // Add a new message in this conversation
            const now = moment().format();
            const newMessage = {
              name: currentUser.name,
              message: messageValue,
              date: now,
            };
            const conversation = contacts[objIndex1];
            conversation.messages.push(newMessage);

            // Make new list of conversation
            const updatedConversations = [
              ...contacts.slice(0, objIndex1),
              conversation,
              ...contacts.slice(objIndex1 + 1),
            ];

            this.setState({ contacts: updatedConversations, sending: false }, this.scrollChatToBottom);
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 402) {
            // Do something
            this.showModal();
          } else {
            this.showNotif(error.response.data.message);
          }
          this.setState({ messageValue, sending: false }, this.scrollChatToBottom);
        });
    } else {
      this.setState({ modalEndTrial: false });
    }
  };

  showModal = () => {
    const { modal } = this.state;
    if (modal) {
      // We're adding this line in case the user clicks
      // on a different ping button in within the 10 seconds
      this.setState({ modal: false }, () => {
        this.setState({ modal: true });
      });
    } else {
      this.setState({ modal: true });
    }
    setTimeout(() => {
      // Resets the state of the modal
      this.setState({ modal: false });
    }, 10000);
  };

  showNotif = (message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color="danger"
        message={message}
      />,
      position: 'full',
    });
  };

  showInfo = (message) => {
    const { showNotification } = this.props;
    return showNotification({
      notification: <FullWideNotification
        color="primary"
        message={message}
      />,
      position: 'full',
    });
  };

  handleChange = (event) => {
    this.setState({ messageValue: event.target.value });
  };

  onOpenChat = (contact, e) => {
    const { contacts } = this.state;
    e.preventDefault();
    const dialog = contacts.find(c => c.id === contact).messages;
    const messages = dialog || null;
    this.setState({
      currentChat: contact,
      currentMessages: messages,
    }, this.scrollChatToBottom());
  };

  onOpenContacts = () => {
    this.setState(prevState => ({ openContacts: !prevState.openContacts }), this.scrollChatToBottom());
  };

  scrollChatToBottom = () => {
    const { scrollbar } = this.$container;
    const height = document.getElementById('scroll-element').clientHeight + 1000;
    setTimeout(() => {
      scrollbar.scrollTo(0, height);
    }, 100);
    return null;
  };

  toggleUpload = () => {
    const { showUpload } = this.state;
    this.setState({ showUpload: !showUpload });
  };

  uploadFile = (e) => {
    const { currentChat, contacts } = this.state;
    const { currentUser } = this.props;
    const formData = new FormData();

    if (typeof e.files !== typeof undefined) {
      formData.append('chatFile', e.files[0]);
      formData.append('id', currentChat);
      this.toggleUpload();
      this.showInfo('Your Document is uploading. Please wait...');

      axios.post('/conversations/upload_file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (response.data) {
          this.setState({ showUpload: false });
          // find the index of this conversation
          const objIndex1 = contacts.findIndex(i => i.id === currentChat);

          // Add a new message in this conversation
          const now = moment().format();
          const newMessage = {
            name: currentUser.name,
            message: response.data,
            date: now,
            file: {
              size: 'OK',
            },
          };
          const conversation = contacts[objIndex1];
          conversation.messages.push(newMessage);

          // Make new list of conversation
          const updatedConversations = [
            ...contacts.slice(0, objIndex1),
            conversation,
            ...contacts.slice(objIndex1 + 1),
          ];

          this.setState({ contacts: updatedConversations }, this.scrollChatToBottom());
        }
      }).catch((error) => {
        this.setState({ showUpload: false });
        if (error && error.response) {
          // Do something error.response.data.message ?
          this.showNotif(error.response.data.message);
        } else {
          this.showNotif('Sorry, an error occurred, please try again.');
        }
      });
    }
  };

  search = (event) => {
    const { contacts } = this.state;
    if (event.target.value.length === 0) {
      this.setState({ contactsList: contacts }, this.scrollChatToBottom());
      return false;
    }
    // First get our Indexes
    const getAllIndexes = (arr, val) => {
      const indexes = [];
      let i;
      for (i = 0; i < arr.length; i += 1) {
        const z = arr[i].name.search(val);
        if (z >= 0) indexes.push(i);
      }
      return indexes;
    };

    // find the index of this conversation
    const regex = new RegExp(event.target.value, 'i');
    const indexes = getAllIndexes(contacts, regex);

    // Make new list of conversation
    // eslint-disable-next-line max-len
    const updatedConversations = contacts.filter((c, i) => typeof indexes.find(index => index === i) !== typeof undefined);

    this.setState({ contactsList: updatedConversations }, this.scrollChatToBottom());
    return true;
  };

  render() {
    const {
      openContacts, currentChat, currentMessages, messageValue,
      contacts, contactsList, modal, showUpload, sending, modalEndTrial,
    } = this.state;

    const participant = contacts.find(c => c.id === currentChat);
    const { currentUser } = this.props;
    const chatClass = classNames({
      chat: true,
      'chat--open': openContacts,
    });

    const contactsClass = classNames({
      'chat__contact-list': true,
      'chat__contact-list--open': openContacts,
    });

    return (
      <div
        className={chatClass}
        onClick={openContacts ? this.onOpenContacts : null}
        role="presentation"
      >
        <div className={contactsClass}>
          <ChatSearch search={this.search} />
          <div className="chat__contacts">
            <Scrollbar className="scroll chat__contacts-scroll" alwaysShowTracks>
              {contactsList.map((c, i) => (
                <button key={i} type="button" onClick={e => this.onOpenChat(c.id, e)}>
                  <ChatContact active={c.id === currentChat} contact={c} />
                </button>
              ))}
            </Scrollbar>
          </div>
        </div>
        {currentChat === null
          ? (
            <div className="chat__dialog">
              <ChatTopbar onClick={this.onOpenContacts} />
              <div className="chat__dialog-select-message">
                <p>Select a chat to start messaging</p>
              </div>
            </div>
          )
          : (
            <div className="chat__dialog">
              <ChatTopbar
                contact={contacts.find(c => c.id === currentChat)}
                onClick={this.onOpenContacts}
              />
              {/* eslint-disable-next-line no-return-assign */}
              <Scrollbar className="scroll chat__scroll" alwaysShowTracks ref={c => this.$container = c}>
                <div id="scroll-element" className="chat__dialog-messages-wrap">
                  {
                    currentMessages === null || currentMessages.length === 0
                      ? (
                        <div className="chat__dialog-messages">
                          <div className="chat__dialog-messages-empty">
                            <p>No messages</p>
                          </div>
                        </div>
                      )
                      : (
                        <div className="chat__dialog-messages">
                          { showUpload
                            ? (
                              <UploadFile
                                onSubmit={this.uploadFile}
                                hideUpload={this.toggleUpload}
                              />
                            )
                            : (
                              <span>
                                {currentMessages.map((m, i) => (
                                  <ChatBubble
                                    key={i}
                                    contact={m.name === currentUser.name ? currentUser
                                      : contacts.find(c => c.name === m.name)}
                                    message={m}
                                    date={m}
                                    active={m.name === currentUser.name}
                                  />
                                ))}
                              </span>
                            )
                        }
                        </div>
                      )
                    }
                </div>
              </Scrollbar>
              {participant.is_blocked
                ? ('')
                : (
                  <ChatField
                    messageValue={messageValue}
                    onSubmit={this.submitForm}
                    handleMessage={this.handleChange}
                    showUpload={this.toggleUpload}
                    sending={sending}
                    isDisabled={participant.is_blocked}
                  />
                )}
            </div>
          )
        }
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modal}
          message={`Please upgrade your account to one of our paid plans if you wish to reply to investors.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
           Pricing Page</a>.`}
        />
        {modalEndTrial && (
          <AccountWarnings
            showModalTrial
            chat
          />
        )}
      </div>
    );
  }
}
