import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';

const loadScript = () => new Promise((resolve) => {
  if (window.hugerte) {
    resolve();
    return;
  }

  if (!document.querySelector('script[src*="hugerte"]')) {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/hugerte@1.0.4/hugerte.min.js';
    script.async = true;
    script.onload = () => {
      console.log('HugeRTE script loaded');
      resolve();
    };
    script.onerror = (e) => {
      console.error('HugeRTE script failed to load:', e);
      resolve(); // Resolve anyway to avoid hanging
    };
    document.head.appendChild(script);
  }

  // Add a safety timeout
  const timeoutId = setTimeout(() => {
    resolve();
  }, 5000);

  // Check periodically if hugerte is loaded
  const interval = setInterval(() => {
    if (window.hugerte) {
      clearTimeout(timeoutId);
      clearInterval(interval);
      resolve();
    }
  }, 100);
});

const imageUploadHandler = (blobInfo, success, failure) => {
  const formData = new FormData();
  formData.append('image', blobInfo.blob());

  if (!blobInfo.blob().type.includes('image')) {
    failure('The file is not a image, please try again with an image.');
    return Promise.reject();
  }

  const imageSize = Math.trunc(blobInfo.blob().size / 1000);
  if (imageSize > 3000) {
    failure('Maximum size : 3M');
    return Promise.reject();
  }

  return axios.post('/campaigns/image/add', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((response) => {
      if (response && response.data) {
        const json = JSON.parse(response.data);
        if (json && json.location) {
          const location = process.env.REACT_APP_AWS_ROOT + process.env.REACT_APP_AWS_ATTACHMENTS_PATH + json.location;
          return location;
        }
      }
      return Promise.reject(new Error('Invalid response format'));
    })
    .catch((err) => {
      failure('Something went wrong, please try again.');
      return Promise.reject(err);
    });
};

const editorConfig = {
  height: 350,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
  ].join(' '),
  toolbar:
    'undo redo | formatselect | bold italic backcolor | '
    + 'alignleft aligncenter alignright alignjustify | '
    + 'bullist numlist outdent indent | removeformat | help',
  block_formats: 'Paragraph=p;Heading 3=h3',
  images_upload_handler: imageUploadHandler,
  convert_urls: false,
  entity_encoding: 'raw',
  verify_html: false,
  cleanup: false,
  paste_data_images: true,
};

const replaceToCamelCase = (data, string) => {
  const regex = new RegExp(string, 'i');
  return data.replaceAll(data.match(regex)[0], string);
};

const renderTinyMCECampaignField = ({
  input,
  meta: { error, submitFailed },
  dispatched = false,
  campaignId,
  id,
  showWarningMessage,
  setDispatch,
}) => {
  const editorRef = useRef(null);
  const editorInstanceRef = useRef(null);
  const [touched, setTouched] = useState(false);
  const [templateId, setTemplateId] = useState(id);

  // Create unique ID for this editor instance based on the field name
  const editorId = `hugeRTE-campaign-editor-${input.name.replace(/\D+/g, '')}`;

  const saveTemplateHandler = async (content) => {
    if (!content) return;

    const contentNoHtml = content.replace(/(<([^>]+)>)/ig, '');
    if (!contentNoHtml) return;

    const titleElement = document.getElementsByName(input.name.replace('content', 'title'))[0];
    let titleText = titleElement ? titleElement.value : '';
    const delayElement = document.getElementsByName(input.name.replace('content', 'delay'))[0];
    const delay = delayElement ? delayElement.value : 0;

    const varTemplates = [
      'investorFirstName',
      'investorLastName',
      'investorVertical',
      'investorCity',
      'investorFund',
      'startupName',
      'signature',
      'lastDeal',
      'trackingLink',
    ];

    let processedContent = content;
    const lowerContent = content.toLowerCase();
    varTemplates.forEach((varTemplate) => {
      if (lowerContent.includes(varTemplate.toLowerCase())) {
        processedContent = replaceToCamelCase(processedContent, varTemplate);
      }
      if (titleText.toLowerCase().includes(varTemplate.toLowerCase())) {
        titleText = replaceToCamelCase(titleText, varTemplate);
      }
    });

    try {
      const response = await axios.post('campaigns/saveTemplate',
        queryString.stringify({
          content: processedContent,
          title: titleText,
          id: templateId,
          delay,
          position: input.name.replace(/\D+/g, ''),
          campaign_id: campaignId,
        }));

      if (response.data) {
        setTemplateId(response.data.templateId);
      }
    } catch (err) {
      console.error('Error saving template:', err);
    }
  };

  useEffect(() => {
    let mounted = true;
    let checkInterval = null;
    let timeoutId = null;

    const initializeEditor = async () => {
      try {
        // Wait for script to load
        await loadScript();

        if (!mounted) return;

        if (!window.hugerte || !editorRef.current) {
          return;
        }

        // Clean up any existing instance
        if (editorInstanceRef.current) {
          try {
            editorInstanceRef.current.destroy();
            editorInstanceRef.current = null;
          } catch (err) {
            console.error('Error destroying existing editor instance:', err);
          }
        }

        // Clean up any other existing editor with the same ID
        const existingEditor = window.hugerte.get(`#${editorId}`);
        if (existingEditor) {
          try {
            existingEditor.destroy();
          } catch (err) {
            console.error('Error destroying existing editor:', err);
          }
        }

        // Initialize new editor
        const editor = await window.hugerte.init({
          ...editorConfig,
          selector: `#${editorId}`,
          initial_value: input.value || '',
          setup: (ed) => {
            if (!mounted) return;

            editorInstanceRef.current = ed;

            ed.on('init', () => {
              if (!mounted) return;
              ed.setContent(input.value || '');
            });

            // Update the change handler in the editor setup
            ed.on('change', () => {
              if (!mounted) return;
              const content = ed.getContent();

              // Only show warning on substantial content changes
              const contentNoHtml = content.replace(/(<([^>]+)>)/ig, '').trim();
              if (contentNoHtml.length > 5 && showWarningMessage) {
                showWarningMessage();
              }

              input.onChange(content);

              // Save handler remains the same
              const saveTimeoutId = setTimeout(() => {
                if (mounted) {
                  saveTemplateHandler(content);
                }
              }, 4000);

              // eslint-disable-next-line consistent-return
              return () => clearTimeout(saveTimeoutId);
            });

            ed.on('blur', () => {
              if (!mounted) return;
              const content = ed.getContent();
              input.onChange(content);
              saveTemplateHandler(content);
              setTouched(true);
            });
          },
        });

        if (!mounted) {
          editor.destroy();
        }
      } catch (err) {
        console.error('Error during editor initialization:', err);
      }
    };

    // Start the initialization process
    initializeEditor();

    // Set up periodic check for hugerte availability
    checkInterval = setInterval(() => {
      if (window.hugerte && editorRef.current && !editorInstanceRef.current) {
        initializeEditor();
      }
    }, 100);

    // Cleanup interval after 10 seconds if editor hasn't initialized
    timeoutId = setTimeout(() => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    }, 10000);

    return () => {
      mounted = false;

      // Clear intervals and timeouts
      if (checkInterval) {
        clearInterval(checkInterval);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Clean up editor instance
      if (editorInstanceRef.current) {
        try {
          editorInstanceRef.current.destroy();
          editorInstanceRef.current = null;
        } catch (err) {
          console.error('Error destroying editor:', err);
        }
      }

      // Clean up any remaining editor with this ID
      if (window.hugerte) {
        const existingEditor = window.hugerte.get(`#${editorId}`);
        if (existingEditor) {
          try {
            existingEditor.destroy();
          } catch (err) {
            console.error('Error destroying existing editor:', err);
          }
        }
      }
    };
  }, [editorId, input.name]); // Add input.name to dependencies to ensure editor reinitializes when field changes

  // Update content when input value changes
  useEffect(() => {
    if (editorInstanceRef.current && input.value !== editorInstanceRef.current.getContent() && dispatched === true) {
      // Check if this update is from template selection
      const isTemplate = (input.value && input.value.isTemplate) ? input.value.isTemplate : undefined;
      const content = isTemplate ? input.value.content : input.value;
      editorInstanceRef.current.setContent(content || '');
      setDispatch(false);
    }
  }, [input.value]);

  return (
    <div className="form__form-group-input-wrap">
      <textarea
        id={editorId}
        ref={editorRef}
        style={{ visibility: 'hidden' }}
        defaultValue={input.value || ''}
      />
      {(dispatched || touched || submitFailed) && error && (
        <span className="form__form-group-error error-large">{error}</span>
      )}
    </div>
  );
};

renderTinyMCECampaignField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  dispatched: PropTypes.bool,
  setDispatch: PropTypes.func.isRequired,
  campaignId: PropTypes.number,
  showWarningMessage: PropTypes.func,
  id: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.string,
  }),
};

renderTinyMCECampaignField.defaultProps = {
  meta: {},
  dispatched: false,
  campaignId: 0,
  id: 0,
  showWarningMessage: null,
};

export default renderTinyMCECampaignField;
