import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import axios from 'axios';
import queryString from 'query-string';
import CancellationFlow from './components/CancellationFlow';
import LoadingSpinner from '../../LoadingSpinner';

class ModalCancelSubscription extends PureComponent {
  state = {
    user: JSON.parse(localStorage.getItem('user')),
    updateError: null,
    cancelledMessage: null,
    loading: false,
    currentStep: 'initial',
    cancellationReason: null,
  };

  static propTypes = {
    displayed: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    toggleModalFeedback: PropTypes.func.isRequired,
    getMyInfo: PropTypes.func.isRequired,
  };

  async componentDidUpdate(prevProps) {
    const { displayed } = this.props;
    // Check if modal is being opened (wasn't displayed before, but is now)
    if (!prevProps.displayed && displayed) {
      try {
        await this.logCancellationAction('Opened');
      } catch (error) {
        // Error is already logged in logCancellationAction
        // You could add additional error handling here if needed
      }
    }
  }

  logCancellationAction = async (action) => {
    try {
      console.log('log');
      await axios.post('/user/log-cancellation', { action });
    } catch (error) {
      console.error('Error logging cancellation action:', error);
    }
  };

  handleReasonSelect = async (reason) => {
    await this.logCancellationAction(reason);
    this.setState({
      currentStep: 'options',
      cancellationReason: reason,
    });
  };

  cancelSubscription = () => {
    const { toggleModalFeedback, getMyInfo } = this.props;
    const { user, cancellationReason } = this.state;

    this.setState({ loading: true, updateError: null });
    this.logCancellationAction('Cancel Plan');
    axios.post('/subscription/cancel', queryString.stringify({ reason: cancellationReason }))
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({ loading: false });
          alert('We are sorry to see you go! Your subscription has been successfully cancelled.');
          this.handleClose();
          if (user.isPayingCustomer) {
            toggleModalFeedback();
          }
          getMyInfo();
        } else {
          this.setState({
            loading: false,
            updateError: response.data.error || 'Sorry, an error occurred, please try again.',
          });
        }
      })
      .catch(this.handleError);
  };

  switchPlan = async (plan) => {
    const { getMyInfo } = this.props;

    this.setState({ loading: true, updateError: null });
    await this.logCancellationAction('Switch Plan');

    axios.post('/subscription/switch', queryString.stringify({ plan }))
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({ loading: false });
          getMyInfo();
          this.handleClose();
        } else {
          this.setState({
            loading: false,
            updateError: response.data.error || 'Sorry, an error occurred, please try again.',
          });
        }
      })
      .catch(this.handleError);
  };

  applyDiscount = async () => {
    try {
      this.setState({ loading: true, updateError: null });
      await this.logCancellationAction('Apply Discount');

      const response = await axios.post('/subscription/apply-cancel-discount');

      if (response.data.success) {
        this.setState({
          loading: false,
          cancelledMessage: response.data.message,
        });
        alert(`Success! ${response.data.message}`);
        setTimeout(() => this.handleClose(), 2000);
      } else {
        this.setState({
          loading: false,
          updateError: response.data.error || 'Failed to apply discount',
        });
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  extendSubscription = async () => {
    try {
      this.setState({ loading: true, updateError: null });

      const response = await axios.post('/subscription/extend');

      if (response.data.success) {
        this.setState({
          loading: false,
          cancelledMessage: 'Your subscription has been extended by 7 days.',
        });
        alert('Your subscription has been extended by 7 days.');
        setTimeout(() => this.handleClose(), 2000);
      } else {
        this.setState({
          loading: false,
          updateError: response.data.error || 'Failed to extend subscription',
        });
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  bookCall = async () => {
    await this.logCancellationAction('Book Call');
    window.open('https://calendly.com/d/cmtn-f77-whf/angels-partners-cancellation-call', '_blank');
    this.extendSubscription();
  };

  handleError = (error) => {
    const errorMessage = (error && error.response && error.response.data && error.response.data.error)
      || 'Sorry, an error occurred, please try again.';
    this.setState({
      loading: false,
      updateError: errorMessage,
    });
  };

  handleClose = () => {
    const { toggleModal } = this.props;
    // First reset our state
    this.setState({
      currentStep: 'initial',
      cancellationReason: null,
      updateError: null,
      cancelledMessage: null,
    }, () => {
      // Then call the parent's toggle function
      toggleModal();
    });
  };

  render() {
    const { displayed } = this.props;
    const {
      user,
      loading,
      updateError,
      cancelledMessage,
      currentStep,
      cancellationReason,
    } = this.state;

    return (
      <Modal
        isOpen={displayed}
        toggle={this.handleClose}
        keyboard
        className="modal-dialog--primary xx-large modal-cancel-subscription"
      >
        {loading ? (
          <LoadingSpinner />
        ) : (
          <CancellationFlow
            user={user}
            updateError={updateError}
            cancelledMessage={cancelledMessage}
            currentStep={currentStep}
            cancellationReason={cancellationReason}
            onReasonSelect={this.handleReasonSelect}
            onSwitchPlan={this.switchPlan}
            onCancelSubscription={this.cancelSubscription}
            onBookCall={this.bookCall}
            onApplyDiscount={this.applyDiscount}
            onClose={this.handleClose}
          />
        )}
      </Modal>
    );
  }
}

export default ModalCancelSubscription;
