import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../alerts/Alert';

const ModalHeader = ({
  title,
  onClose,
  error,
  success,
  showBackButton,
  onBack,
}) => (
  <div className="modal__header">
    <button
      className="lnr lnr-cross modal__close-btn"
      type="button"
      onClick={onClose}
    />
    {showBackButton && (
      <button
        className="lnr lnr-arrow-left modal__back-btn"
        type="button"
        onClick={onBack}
      />
    )}
    <h4 className="modal__title">{title}</h4>
    <img
      src={`${process.env.PUBLIC_URL}/img/linePurple.svg`}
      className="line"
      alt="line"
    />
    {error && (
      <Alert color="danger" className="alert--colored w-100 mb-5">
        <p className="text-left">
          <span className="bold-text">Error!</span>&nbsp;
          {error}
        </p>
      </Alert>
    )}
    {success && (
      <Alert color="primary" className="alert--colored w-100 mb-5">
        <p className="text-left">
          <span className="bold-text">Success!</span>&nbsp;
          {success}
        </p>
      </Alert>
    )}
  </div>
);

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string,
  success: PropTypes.string,
  showBackButton: PropTypes.bool,
  onBack: PropTypes.func,
};

ModalHeader.defaultProps = {
  error: null,
  success: null,
  showBackButton: false,
  onBack: () => {},
};

export default ModalHeader;
