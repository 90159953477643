// constants/cancellation.js
export const CANCELLATION_REASONS = [
  {
    id: 'expensive',
    title: 'Too Expensive',
    description: 'Our pricing does not fit your budget or you are looking for more cost-effective options.',
  },
  {
    id: 'hard_to_setup',
    title: 'Hard to Setup',
    description: 'You are experiencing difficulties in setting up or configuring the platform to meet your needs.',
  },
  {
    id: 'no_results',
    title: 'No Results',
    description: 'You have not achieved the expected results or outcomes from using our platform.',
  },
  {
    id: 'unused',
    title: "Don't Use It",
    description: 'You are not actively using the platform or its features in your workflow.',
  },
];

export const PLANS = {
  WEB: 'alt_web_plan',
  DISCOUNTED: 'discounted_plan',
};
