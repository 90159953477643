import React from 'react';
import PropTypes from 'prop-types';
import InitialScreen from './InitialScreen';
import OptionsScreen from './OptionsScreen';

const propTypes = {
  user: PropTypes.shape({
    isPayingCustomer: PropTypes.bool,
    paymentGateway: PropTypes.string,
    isCustomer: PropTypes.bool,
  }).isRequired,
  updateError: PropTypes.string,
  cancelledMessage: PropTypes.string,
  currentStep: PropTypes.oneOf(['initial', 'options']).isRequired,
  cancellationReason: PropTypes.string,
  onReasonSelect: PropTypes.func.isRequired,
  onSwitchPlan: PropTypes.func.isRequired,
  onCancelSubscription: PropTypes.func.isRequired,
  onBookCall: PropTypes.func.isRequired,
  onApplyDiscount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  updateError: null,
  cancelledMessage: null,
  cancellationReason: null,
};

const CancellationFlow = ({
  user,
  updateError,
  cancelledMessage,
  currentStep,
  cancellationReason,
  onReasonSelect,
  onSwitchPlan,
  onCancelSubscription,
  onBookCall,
  onApplyDiscount,
  onClose,
}) => {
  const renderContent = () => {
    const screens = {
      initial: (
        <InitialScreen
          onReasonSelect={onReasonSelect}
          onClose={onClose}
        />
      ),
      options: (
        <OptionsScreen
          user={user}
          updateError={updateError}
          cancelledMessage={cancelledMessage}
          cancellationReason={cancellationReason}
          onSwitchPlan={onSwitchPlan}
          onCancelSubscription={onCancelSubscription}
          onBookCall={onBookCall}
          onApplyDiscount={onApplyDiscount}
          onClose={onClose}
        />
      ),
    };

    return screens[currentStep] || null;
  };

  return renderContent();
};

CancellationFlow.propTypes = propTypes;
CancellationFlow.defaultProps = defaultProps;

export default CancellationFlow;
