import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { CANCELLATION_REASONS } from '../constants/cancellation';
import ModalHeader from './ModalHeader';
import OptionBox from './OptionBox';

const InitialScreen = ({ onReasonSelect, onClose }) => (
  <div>
    <ModalHeader
      title="Why are you leaving?"
      onClose={onClose}
    />
    <Row className="subscription-boxes">
      {CANCELLATION_REASONS.map(({ id, title, description }) => (
        <Col key={id} xs={12} md={6} className="mb-4">
          <OptionBox
            title={title}
            subtitle={description}
            buttonText="Select"
            onClick={() => onReasonSelect(id)}
          />
        </Col>
      ))}
    </Row>
  </div>
);

InitialScreen.propTypes = {
  onReasonSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InitialScreen;
