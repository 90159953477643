import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

const OptionBox = ({
  title,
  subtitle,
  buttonText,
  onClick,
  className,
  id,
}) => (
  <div
    className={`subscription-boxes-box small ${className || ''}`}
    id={id}
  >
    <h2 className="subscription-boxes-title">{title}</h2>
    {subtitle && (
      <p className="subscription-boxes-subtitle">{subtitle}</p>
    )}
    <Button
      color="primary"
      className="rounded icon icon--right select-boxes-go"
      onClick={onClick}
    >
      {buttonText} <ChevronRightIcon />
    </Button>
  </div>
);

OptionBox.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
};

OptionBox.defaultProps = {
  subtitle: null,
  className: '',
  id: null,
};

export default OptionBox;
