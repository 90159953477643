import React, { PureComponent } from 'react';
import {
  Button, ButtonToolbar, Modal,
} from 'reactstrap';
import SendIcon from 'mdi-react/SendIcon';
import PropTypes from 'prop-types';

const picture = `${process.env.REACT_APP_DOMAIN_URL}/platform/images/pitch-deck-review.jpg`;

class ModalDeckReview extends PureComponent {
  static propTypes = {
    displayed: PropTypes.bool,
    toggleModal: PropTypes.func,
  };

  static defaultProps = {
    displayed: false,
    toggleModal: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: props.displayed,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ modal: newProps.displayed });
  }

  contact = () => {
    window.location = 'mailto:yohann@angelspartners.com?subject=Inquiry for Deck Review!';
  }

  toggle() {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ modal: !prevState.modal }));
    if (typeof toggleModal === 'function') {
      toggleModal();
    }
  }

  render() {
    const { modal } = this.state;
    return (
      <Modal
        isOpen={modal}
        toggle={this.toggle}
        modalClassName="ltr-support"
        className="modal-dialog--primary modal-templates xx-large"
      >
        <div className="modal__header">
          <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.toggle} />
          <h4>Deck Review</h4>
          <img src={`${process.env.PUBLIC_URL}/img/line.svg`} className="modal__line" alt="line" />
        </div>
        <div className="modal__body mt-5">
          <div className="section section-pad section-bg-btoa" id="bootcamp">
            <div className="container">

              <div className="row text-center">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="section-head">
                    <h3 className="animated" data-animate="fadeInUp" data-delay=".1">
                      <strong>Let us review your deck in depth</strong>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="col-md-8 offset-md-2 mb-5 mt-3">
                <p className="lead">
                  <br />
                  <br />
                  <img src={picture} alt="Francois Mazoudier" />
                </p>
              </div>

              <div className="m-5 text-justify">
                <p>
                  Your pitch deck is the first—and often only—chance to make a powerful impression on investors.
                  At Angels Partners, we offer a comprehensive review service to help you put your best foot forward.
                  Our expert team, with years of experience in fundraising and startup success, will meticulously assess
                  every element of your deck.
                  We’ll evaluate its structure and content, ensuring it meets the expectations of today’s top investors.
                </p>
                <p>
                  We’ll provide detailed feedback on key aspects of your deck, such as whether your message is clear
                  and compelling, your market analysis is well-defined,
                  and your business model is solid. We’ll also examine the credibility of your financials, the strength
                  of your solution, and pinpoint any crucial elements
                  that may be missing. Our goal is to guide you in creating a deck that tells a compelling, data-driven
                  story designed to capture investor interest.
                </p>
              </div>

              <div className="m-5">
                <div>
                  <h4 className="mb-3"><strong>The review includes:</strong></h4>
                  <ul className="styled">
                    <li>
                      Complete review of your deck, its structure & different elements that should be in any deck.
                    </li>
                    <li>
                      Do you have the right message?
                    </li>
                    <li>
                      Is your market analysis clear?
                    </li>
                    <li>
                      Is your business model strong enough?
                    </li>
                    <li>
                      Is your solution convincing enough to investors?
                    </li>
                    <li>
                      Are you financials credible?
                    </li>
                    <li>
                      What is missing in your deck?
                    </li>
                    <li>
                      ... and much more.
                    </li>
                  </ul>
                </div>
                <br />
                <br />
                <div>
                  <h4 className="mb-3"><strong>What it does not include:</strong></h4>
                  <ul className="styled">
                    <li>
                      A complete revamp of your deck. You will have to make the changes yourself.
                    </li>
                    <li>
                      Edits on your deck. We only give comments and advices
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <ButtonToolbar className="modal__footer float-right">
            <a href="mailto:yohann@angelspartners.com&subject=Deck Review - Inquiry
                    &body=Please let us know any question you have regarding this service"
            >
              <Button
                className="modal_ok rounded icon icon--right icon--blue"
                type="button"
                color="primary"
                onClick={this.contact}
              >
                Get Started <SendIcon />
              </Button>
            </a>
          </ButtonToolbar>
        </div>
      </Modal>
    );
  }
}

export default ModalDeckReview;
