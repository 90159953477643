import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import Alert from '../../../alerts/Alert';
import ModalHeader from './ModalHeader';

const propTypes = {
  user: PropTypes.shape({
    isPayingCustomer: PropTypes.bool,
    paymentGateway: PropTypes.string,
    isCustomer: PropTypes.bool,
  }).isRequired,
  updateError: PropTypes.string,
  cancelledMessage: PropTypes.string,
  cancellationReason: PropTypes.string,
  onSwitchPlan: PropTypes.func.isRequired,
  onCancelSubscription: PropTypes.func.isRequired,
  onBookCall: PropTypes.func.isRequired,
  onApplyDiscount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  updateError: null,
  cancelledMessage: null,
  cancellationReason: null,
};

const OptionsScreen = ({
  user,
  updateError,
  // eslint-disable-next-line no-unused-vars
  cancelledMessage,
  cancellationReason,
  onSwitchPlan,
  onCancelSubscription,
  onBookCall,
  onApplyDiscount,
  onClose,
}) => {
  const renderCallOption = () => {
    if (!['setup', 'no_results'].includes(cancellationReason)) {
      return null;
    }

    return (
      <Col xs={12} md={12} lg={4}>
        <div className="offer subscription-boxes-box offer">
          <div className="content-wrapper">
            <h2 className="subscription-boxes-title">Schedule a Call</h2>
            <p className="subscription-boxes-subtitle">
              Let us help you get the most out of our platform:
            </p>
            <ul className="subscription-boxes-bullets">
              <li><strong>Get a one-to-one call</strong> with one of our specialists</li>
              <li>Improve your performance on the platform</li>
              <li>Learn how to schedule more meetings with investors</li>
            </ul>
            <p className="subscription-boxes-subtitle">
              We&apos;ll extend your subscription by 7 days so you have time to schedule and attend the call.
            </p>
            <Button
              type="button"
              color="primary"
              className="rounded icon icon--right select-boxes-go"
              onClick={onBookCall}
            >
              Book Call <ChevronRightIcon />
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const renderDiscountOption = () => {
    if (cancellationReason !== 'expensive') {
      return null;
    }

    return (
      <Col xs={12} md={12} lg={4}>
        <div className="offer subscription-boxes-box">
          <div className="content-wrapper">
            <h2 className="subscription-boxes-title">Get a Discount</h2>
            <p className="subscription-boxes-subtitle">
              <strong>Save 50% on your next subscription. </strong>
              If you are under a monthly plan, it will apply for one month.
              If you are under a quarterly plan, it will apply to your next quarter.
            </p>
            <p className="subscription-boxes-subtitle">
              Your subscription will return to its initial price on the following renewal unless you cancel.
            </p>
            <Button
              type="button"
              color="primary"
              className="rounded icon icon--right select-boxes-go discount-btn"
              onClick={onApplyDiscount}
            >
              Apply Discount <ChevronRightIcon />
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const renderWebPlanOption = () => {
    if (!user.isPayingCustomer || user.paymentGateway !== 'stripe') {
      return null;
    }

    const colSize = cancellationReason === 'unused' ? 8 : 4;

    return (
      <Col xs={12} md={6} lg={colSize}>
        <div id="switch-plan" className="subscription-boxes-box">
          <div className="content-wrapper">
            <h2 className="subscription-boxes-title">
              Remain listed & update investors
            </h2>
            <p className="subscription-boxes-subtitle mb-3">
              <strong>Switch to our cheapest plan at $19.99/month</strong> in order to:
            </p>
            <ul className="subscription-boxes-bullets">
              <li>Stay listed on the platform, investors can still contact you</li>
              <li>Send updates to investors you previously contacted</li>
              <li>Keep using your tracking links</li>
              <li>Keep access to our legal documents</li>
              <li>Keep the pings left on your account</li>
            </ul>
            <Button
              type="button"
              color="primary"
              className="rounded icon icon--right select-boxes-go"
              onClick={() => onSwitchPlan('alt_web_plan')}
            >
              Switch Plan <ChevronRightIcon />
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const renderCancelOption = () => (
    <Col xs={12} md={6} lg={4}>
      <div className="subscription-boxes-box">
        <div className="content-wrapper">
          <h2 className="subscription-boxes-title">Unsubscribe</h2>
          <p className="subscription-boxes-subtitle">
            <strong>You will lose your pings</strong>, all of your data will be removed from the platform and you
            will not be listed to our panel of investors.
          </p>
          <p className="subscription-boxes-subtitle">
            You will not be able to contact investors anymore.
          </p>
          <Button
            type="button"
            color="primary"
            className="rounded icon icon--right select-boxes-go"
            onClick={onCancelSubscription}
          >
            Cancel Now <ChevronRightIcon />
          </Button>
        </div>
      </div>
    </Col>
  );

  return (
    <div>
      <div className="modal__header">
        <ModalHeader
          title="What would you prefer?"
          onClose={onClose}
        />
        {updateError && (
          <Alert color="danger" className="alert--colored w-100 mb-5">
            <p className="text-left">
              <span className="bold-text">Error!</span>
              &nbsp;
              {updateError}
            </p>
          </Alert>
        )}
        {cancelledMessage && (
          <Alert color="info" className="alert--colored mt-5 w-100 mb-5">
            <p className="text-left">
              <span className="bold-text">Success!</span>
              &nbsp;
              {cancelledMessage}
            </p>
          </Alert>
        )}
      </div>
      <Row className="subscription-boxes">
        {renderCallOption()}
        {renderDiscountOption()}
        {renderWebPlanOption()}
        {renderCancelOption()}
      </Row>
    </div>
  );
};

OptionsScreen.propTypes = propTypes;
OptionsScreen.defaultProps = defaultProps;

export default OptionsScreen;
